<template>
  <div id="viewAjustes">
    <h1>AJUSTES</h1>
    <span>{{ $store.state.credential }}</span>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  }
};
</script>