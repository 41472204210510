export default {
  getDebug: (state) => {
    return state.debug
  },
  getUrlGateway: (state) => {
    return state.urlGateway
  },
  getClientId: (state) => {
    return state.clientId
  },
  getAuthURL: (state) => {
    return state.authURL
  },
  getRedirectURL: (state) => {
    return state.redirectURL
  },
  getUrlWS: (state) => {
    return state.urlWS
  },
  getCredential: (state) => {
    return state.credential
  },
  getUser: (state) => {
    return state.user
  },
  getPass: (state) => {
    return state.pass
  },
  getDiaryView: (state) => {
    return state.diaryView
  },
  getHour: (state) => (tipo) => {
    var auxHora = (tipo == "E") ? state.diaryView.HorEntradaReal : state.diaryView.HorSalidaReal
    var sinHora = false;

    if (!auxHora) {
      auxHora = "-- h."
      sinHora = true;
    }

    if (sinHora) {
      //si no he realizado el fichaje, no pongo la hora estimada ni de entrada ni de salida
      var auxHoraReal = state.diaryView.HorEntradaReal;
      if (auxHoraReal) {
        var auxHoraTheor = (tipo == "E") ? state.diaryView.HorEntradaTeor : state.diaryView.HorSalidaTeor
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDay();
        var hour = auxHoraTheor.substring(0, 2);
        var minutes = auxHoraTheor.substring(3, 5);
        var seconds = 0;
        var auxtheorFec = new Date(year, month, day, hour, minutes, seconds);
        var ht = auxtheorFec.getHours(); var mt = auxtheorFec.getMinutes();
        ht = (ht < 10) ? '0' + ht : ht
        mt = (mt < 10) ? '0' + mt : mt
        auxHora = (tipo == "E") ? ht + ":" + mt : ht + ":" + mt + " (Est)"
      } else {
        auxHora = "-- h."
      }
    }
    return auxHora;
  },
  getComplete: (state) => {
    if (state.diaryView.HorSalidaReal) {
      return true;
    } else {
      return false;
    }
  },
  getUserName: (state) => {
    return state.userName;
  },
  getSecWorked: (state) => {
    /*var diffSecs = 0;
    if((state.lastgetSecWorkedStored)&&(state.diaryView.HorSalidaReal == "")&&(state.diaryView.HorEntradaReal != "")){
      var now = new Date();
      var lastSecWorked = state.lastgetSecWorkedStored
      if(typeof state.lastgetSecWorkedStored == "string"){
        lastSecWorked = new Date(lastSecWorked);
      }
      diffSecs = (now - lastSecWorked)/1000
      console.log("getters > getSecWorked " + typeof lastSecWorked)
      console.log(" > lastgetSecWorkedStored: "+lastSecWorked.getTime())
      console.log(" > now: "+now.getTime())
      console.log(" > secWorked:" + state.diaryView.secWorked)
      console.log(" > diffSecs:" + diffSecs)
    }
    return state.diaryView.secWorked + diffSecs;*/
    return state.diaryView.secWorked;  
  },
  getAllWeekView: (state) => {
    return state.weekView;
  },
  getweekView: (state) => (fecWeek) => {
    var auxfec = new Date(fecWeek.getTime());
    auxfec.setHours(0, 0, 0, 0);

    var arrItemsFilter = window.$.grep(state.weekView, function (item) {
      let auxFecIniSemana = new Date(item.fecIniSemana);
      auxFecIniSemana.setHours(0, 0, 0, 0);
      return auxFecIniSemana.getTime() == auxfec.getTime()
    });

    return arrItemsFilter
  },
  preguntasFinde: (state, getters) => (objDatos) => {

    if (state.debug) {
      console.log("getters > preguntasFinde");
      console.log(objDatos);
    }

    return new Promise(async function (resolve, reject) {
      let respPregFinde = "S"
      if (objDatos.indexDia > 4) {
        await getters.alertIonic("YESNO", "Pregunta", state.jsonConfig.msg_fichajeFinde).then(res => {
          respPregFinde = res ? "S" : "N";

        });
      }

      if (respPregFinde == "S") {
        resolve();
      } else {
        reject(new Error('No se inserta fichaje'));
      }
    });

  },
  preguntasSalida: (state, getters) => (objDatos) => {

    if (state.debug) {
      console.log("getters > preguntasSalida");
      console.log(objDatos);
    }

    return new Promise(async function (resolve, reject) {
      let respExcesoComida = null;
      let respExcesoHoras = null;
      let respTeletrabajo = null;
      let segsComparar = objDatos.segsJornada

      let respPregFinde = "S"
      if (objDatos.indexDia > 4) {
        await getters.alertIonic("YESNO", "Pregunta", state.jsonConfig.msg_fichajeFinde).then(res => {
          respPregFinde = res ? "S" : "N";

        });
      }

      if (respPregFinde == "S") {

        if (objDatos.chkComida == "true") {
          await getters.alertIonic("YESNO", "Pregunta", state.jsonConfig.msg_pausaComida).then(res => {
            respExcesoComida = res ? "S" : "N";
            segsComparar = res ? objDatos.segsJornadaComida : objDatos.segsJornada;
          });
        }

        /*let msgAlert  = "segsJornadaTrabajada : " + objDatos.segsJornadaTrabajada + "\n";
            msgAlert += "segsComparar : " + segsComparar + "\n";
            msgAlert += "segsJornadaTrabajada > segsComparar : " + (objDatos.segsJornadaTrabajada > segsComparar) + "\n";
        alert(msgAlert);*/

        if (objDatos.segsJornadaTrabajada > segsComparar) {
          var d = Number(objDatos.segsJornadaTrabajada);
          var h = Math.floor(d / 3600);
          var m = Math.floor((d % 3600) / 60);
          //var s = Math.floor(d % 3600 % 60);

          var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
          var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos ") : "";
          //var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
          var totDisplay = hDisplay + mDisplay; // + sDisplay;

          //Reemplazamos la variables ##TOTAL_HORAS##
          var auxMsgReplace = state.jsonConfig.msg_excesoJornada.replace("##TOTAL_HORAS##", totDisplay);
          await getters.alertIonic("YESNO", "Pregunta", auxMsgReplace).then(async res => {
            respExcesoHoras = res ? "S" : "N";
            if (res) {
              await getters.alertIonic("OK", "", state.jsonConfig.msg_confirmExceso);
            }
          });

        }

        await getters.alertIonic("YESNO", "Pregunta", state.jsonConfig.msg_teletrabajo).then(res => {
          respTeletrabajo = res ? "S" : "N";
        });

        var objRet = { respExcesoComida: respExcesoComida, respExcesoHoras: respExcesoHoras, respTeletrabajo: respTeletrabajo }
        resolve(objRet);

      } else {
        reject(new Error('No se inserta fichaje'))
      }

    });

  },
  alertIonic: () => async (type, header, msg) => {

    if (window.$("ion-alert-controller").length > 0) {

      const controller = document.querySelector('ion-alert-controller');
      return new Promise((resolve, reject) => {

        var arrBtns = new Array(); var objBtn;
        if (type == "OK") {
          objBtn = new Object();
          objBtn.text = "OK";
          objBtn.handler = () => {
            resolve(true)
          };
          arrBtns.push(objBtn)
        } else if (type == "session") {
          objBtn = new Object();
          objBtn.text = "OK";
          objBtn.handler = () => {
            //window.location.replace("/");
          };
          arrBtns.push(objBtn)
        } else if (type == "YESNO") {
          objBtn = new Object();
          objBtn.text = "SI";
          objBtn.handler = () => {
            resolve(true)
          };
          arrBtns.push(objBtn)
          objBtn = new Object();
          objBtn.text = "NO";
          objBtn.handler = () => {
            resolve(false)
          };
          arrBtns.push(objBtn)
        } else {
          reject("Tipo de botón no definido");
        }

        controller.create({
          header: header,
          message: msg,
          buttons: arrBtns
        })
          .then(alert => {
            // Now we just need to present the alert
            alert.present();
          });
      });

    } else {
      console.log("No se puede mostrar la alerta ya que no existe el elemento 'ion-alert-controller'")
    }
  },
  getJsonConfig: (state) => {
    return state.jsonConfig;
  },
  transformJsonConfig: (state) => (arrStrConfig) => {

    if (state.debug){
      console.log(" ## transformJsonConfig ##");
      console.log(" > Inicio: ");
      console.log(arrStrConfig);
    }


    //Transformamos de un array de string a un array de JSON's
    let arrObjConfig = arrStrConfig.map(function(config) {
      let auxConfig = config;
      if (typeof auxConfig == "string") {
        auxConfig = JSON.parse(auxConfig.replace(/\r?\n|\r/g, ' '));
      }
      return auxConfig;
    });

    if (state.debug){
      console.log(" > Transformación 1: ");
      console.log(arrObjConfig);
    }

    //Transformamos de un array de tipo [{'id':'A', value:'V1'},{'id':'B', value:'V2'}] a un objeto {'A':'V1','B':'V2'}
    let objConfigFormat = new Object()
    window.$.each(arrObjConfig, function(i,config){
      objConfigFormat[config.id] = config.value
    })

    if (state.debug){
      console.log(" > Transformación 2: ");
      console.log(objConfigFormat);
      console.log(" ## FIN transformJsonConfig ##");
    }

    return objConfigFormat;
  },
  getLocalConfiguration: (state, getters) => {
    let arrStrConfig = new Array();
    arrStrConfig.push('{"id":"ctc_telefono", "type":"input", "value":"91 807 58 88 (Ext. 75888)"}');
    arrStrConfig.push('{"id":"ctc_email", "type":"input", "value":"contigo.RRHH-Espana@gsk.com"}');
    arrStrConfig.push('{"id":"msg_pausaComida", "type":"textarea", "value":"¿Has realizado pausa de comida?"}');
    arrStrConfig.push('{"id":"msg_fichajeFinde", "type":"textarea", "value":"Te recordamos que los fichajes en fin de semana son excepcionales y atienden a razones puntuales  de negocio. ¿Confirmas que quieres introducir fichajes?"}');
    arrStrConfig.push('{"id":"msg_excesoJornada", "type":"textarea", "value":"¿Confirmas que has excedido tu jornada efectiva de trabajo teniendo en cuenta los tiempos de descanso y la politica de flexibilidad vigente en la compañía?  (marcaje de ##TOTAL_HORAS## de presencia)"}');
    arrStrConfig.push('{"id":"msg_confirmExceso", "type":"textarea", "value":"Habla con tu manager sobre la naturaleza de esta situación"}');
    arrStrConfig.push('{"id":"msg_confirmFichajeEntrada", "type":"textarea", "value":"Entrada registrada correctamente."}');
    arrStrConfig.push('{"id":"msg_confirmFichajeSalida", "type":"textarea", "value":"Salida registrada correctamente."}');
    arrStrConfig.push('{"id":"msg_confirmFichajeSalidaHorario", "type":"textarea", "value":"Salida registrada correctamente. Presencia de ##TOTAL_HORAS##"}');
    arrStrConfig.push('{"id":"msg_confirmIncidencia", "type":"textarea", "value":"Incidencia registrada correctamente"}');
    arrStrConfig.push('{"id":"msg_teletrabajo", "type":"textarea", "value":"¿Has teletrabajado?"}');
    arrStrConfig.push('{"id":"cfg_numDiasAtras", "type":"input", "value":30}');
    arrStrConfig.push('{"id":"cfg_numDiasAlante", "type":"input", "value":0}');
    arrStrConfig.push('{"id":"ctc_emailAsunto", "type":"input", "value":"Optime GSK"}');
    
    let obConfig = getters.transformJsonConfig(arrStrConfig);
    return obConfig;
  },
  getLocalDiaryView: () => (fecdia) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (fecdia == null) { fecdia = new Date(); }
    fecdia.setHours(0, 0, 0, 0);
    let month = '' + (fecdia.getMonth() + 1);
    let day = '' + fecdia.getDate();
    let year = fecdia.getFullYear();

    month = (month.length < 2) ? '0' + month : month;
    day = (day.length < 2) ? '0' + day : day;

    var auxMinEntrada = Math.floor(Math.random() * 6) + 1;
    var auxMinSalida = Math.floor(Math.random() * 6) + 1;
    auxMinEntrada =
      auxMinEntrada < 10
        ? "0" + auxMinEntrada.toString()
        : auxMinEntrada.toString();
    auxMinSalida =
      auxMinSalida < 10
        ? "0" + auxMinSalida.toString()
        : auxMinSalida.toString();

    let fDateIni = year + "-" + month + "-" + day + " 09:" + auxMinEntrada;
    let fDateFin = year + "-" + month + "-" + day + " 18:" + auxMinSalida;
    let fHourIni = (fecdia <= today) ? "09:" + auxMinEntrada : "";
    let fHourFin = (fecdia <= today) ? "18:" + auxMinSalida : "";
    let fDateReg = year + "-" + month + "-" + day + " 00:00:00";
    let diffDate = (new Date(fDateFin) - new Date(fDateIni)) / 1000;

    //Agregamos sin fichar con una probabilidad
    var rndFich = Math.floor(Math.random() * 10);

    //Agregamos incidencias con una probabilidad
    var rndAus = Math.floor(Math.random() * 10);
    var arrIncEmpleado = new Array();
    if (rndFich > 1) {
      if (rndAus < 3) {
        var rndBorrable = Math.floor(Math.random() * 10);
        if (rndBorrable < 5) {
          arrIncEmpleado.push({ id: "B", name: "Cuentitis" + rndBorrable, esEliminable: "false" });
        } else {
          arrIncEmpleado.push({ id: "B", name: "Cuentitis" + rndBorrable, esEliminable: "true" });
        }
      }
    }else{
      /*fHourIni = null;
      fHourFin = null;*/
      diffDate = null;
    }

    let auxTeletrab = (Math.random() < 0.5) ? "S" : "N";
    let objDV = {
      HorEntradaTeor: "09:00",
      HorSalidaTeor: "15:00",
      HorEntradaReal: fHourIni,
      HorSalidaReal: fHourFin,
      fecRegistro: fDateReg,
      textoJornadaDiaria: "",
      esFestivo: "false",
      teletrabajo: auxTeletrab,
      secWorked: diffDate,
      btnInActivo: "true",
      btnOutActivo: "false",
      preguntaComida: "true",
      segundosTeoricos: "32940",
      segundosTeoricosComida: "23220",
      incEmpleado: arrIncEmpleado,
      listadoInc: [{ id: "1", name: "cuentitis" }]
    }

    return objDV;

  },
  getLocalweekView: (state, getters) => (fecWeek) => {
    var finSemana = new Date(fecWeek.getTime());
    finSemana.setDate(finSemana.getDate() + 6);

    let monthIni = '' + (fecWeek.getMonth() + 1);
    let dayIni = '' + fecWeek.getDate();
    let yearIni = fecWeek.getFullYear();
    monthIni = (monthIni.length < 2) ? '0' + monthIni : monthIni;
    dayIni = (dayIni.length < 2) ? '0' + dayIni : dayIni;
    let fDateIni = yearIni + "-" + monthIni + "-" + dayIni;

    let monthFin = '' + (finSemana.getMonth() + 1);
    let dayFin = '' + finSemana.getDate();
    let yearFin = finSemana.getFullYear();
    monthFin = (monthFin.length < 2) ? '0' + monthFin : monthFin;
    dayFin = (dayFin.length < 2) ? '0' + dayFin : dayFin;
    let fDateFin = yearFin + "-" + monthFin + "-" + dayFin;

    let objWV = {
      fecIniSemana: fDateIni + " 00:00:00",
      fecFinSemana: fDateFin + " 00:00:00",
      datosSemana: new Array()
    }
    for (
      var i = new Date(fecWeek.getTime());
      i <= finSemana;
      i.setDate(i.getDate() + 1)
    ) {
      var objDia = new Object();
      objDia.resultData = new Object();
      objDia.resultData.diaryView = getters.getLocalDiaryView(i);
      objWV.datosSemana.push(objDia);
    }
    return objWV;
  },
}