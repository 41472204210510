export default {
    SET_LAYOUT(state, newLayout) {
        if (state.layout != newLayout) {
            state.layout = newLayout;
        }
    },
    SET_CREDENTIAL(state, credential) {
        state.credential = credential;
    },
    SET_USERNAME(state, userName) {
        state.userName = userName;
    },
    SET_ACCESS_TOKEN(state, accessToken) {
        state.accessToken = accessToken
    },
    SET_CONFIG(state, jsonConfig) {
        state.jsonConfig = jsonConfig;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_PASS(state, pass) {
        state.pass = pass;
    },
    SET_DIARYVIEW(state, objDiary) {
        state.diaryView = objDiary;
        state.lastgetSecWorkedStored = new Date();
    },
    SET_WEEKVIEW(state, objWeek) {
        state.weekView = objWeek;
    },
    ADD_WEEKVIEW(state, objWeek) {
        if (typeof objWeek.fecIniSemana === 'string') {
            objWeek.fecIniSemana = new Date(objWeek.fecIniSemana)
        }
        objWeek.fecIniSemana.setHours(0, 0, 0, 0)
        state.weekView.push(objWeek);
    },
    ADD_SECWORKED(state,secs){
        state.diaryView.secWorked += secs;
    }
}