var DEBUG = (window.location.host == "localhost:8080") ? true : false;

var global = {
    ponerCarga() {
        window.$(".imgCargaClass").remove();

        var idContent = "#mainContent"; //loginContent
        if (window.$(idContent).length == 0) {
            idContent = "#loginContent";
        }
        if (window.$(idContent).length > 0) {
            window.$(idContent).children().hide();
            window.$(idContent).append('<ion-row justify-content-center align-items-center class="ionRowCenter imgCargaClass" id="imgCarga"></ion-row>');
            window.$("#imgCarga").append('<div><img src="/assets/img/optimeLogo.png" style="width: 250px;" /></div>');
            window.$("#imgCarga").append('<div class="cssload-container"> <div class="cssload-bouncywrap"> <div class="cssload-cssload-dotcon cssload-dc1"> <div class="cssload-dot"></div> </div> <div class="cssload-cssload-dotcon dc2"> <div class="cssload-dot"></div> </div> <div class="cssload-cssload-dotcon dc3"> <div class="cssload-dot"></div> </div> </div> </div>');
        } else {
            if (DEBUG) { console.log("No se puede poner la carga ya que no existe el elemento 'mainContent'") }
        }

    },
    quitarCarga() {
        var idContent = "#mainContent";
        if (window.$(idContent).length == 0) {
            idContent = "#loginContent";
        }

        window.$(".imgCargaClass").remove();
        if (window.$(idContent).length > 0) {
            window.$(idContent).children().show();
        }

    },
    formatDate: function (date, type) {
        var auxDate = date;

        if (typeof auxDate === "string") {
            //auxDate = new Date(auxDate);
            //compatibilidad IOS-ANDROID: https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios/13363791
            auxDate = new Date(auxDate.replace(' ', 'T'));
        }

        var day = auxDate.getDate();
        var month = auxDate.getMonth() + 1;
        var year = auxDate.getFullYear();

        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;

        if (type && type == "M4") {
            return year + "-" + month + "-" + day;
        }else if (type && type == "DAY") {
            return day;
        }else if (type && type == "DATE") {
            return auxDate;
        } else {
            return day + "/" + month + "/" + year;
        }
    },
    secondsToHms: function (segs) {
        if (segs >= 0) {
            var d = Number(segs);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);

            h = (h < 10) ? '0' + h : h
            m = (m < 10) ? '0' + m : m
            s = (s < 10) ? '0' + s : s
            return h + ":" + m + ":" + s;
        } else {
            return "";
        }
    }
}

export default global