'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import Mutations from '@/store/mutations.js'
import Actions from '@/store/actions.js'
import Getters from '@/store/getters.js'
import createPersistedState from 'vuex-persistedstate'
//import * as Cookies from 'js-cookie'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    layout: 'login-layout',
    credential: null,
    userName: '',
    diaryView: new Object(),
    weekView: new Array(),
    jsonConfig: new Object(),
    urlWS: (window.location.host.includes("staging-iis")) ? "https://gsctest.pn.cegid.cloud/REST/" : "https://gsc.pn.cegid.cloud/REST/",
    //urlWS: "http://uk1sawn01831.wmservice.corpnet1.com:8080/REST/",   //DESA
    //urlWS: "https://uk1sawn01869.wmservice.corpnet1.com/REST/",       //PROD 
    //urlWS: "http://uk1sawn01869.wmservice.corpnet1.com:8080/REST/",   //PROD NO SEC
    entorno: (window.location.host.includes("staging-iis")) ? "DESA" : "PROD",
    urlGateway: "/gateway.aspx",
    rejectUnauthorized: true,
    user: null,
    pass: null,
    debug: (window.location.host == "localhost:8080") ? true : false,
    lastgetSecWorkedStored: null,
    //Variables del pingID
    authURL: (window.location.host.includes("staging-iis")) ? 'https://federation-qa.corpnet4.com/as/authorization.oauth2' : 'https://federation.corpnet4.com/as/authorization.oauth2',
    introspectionRUL: (window.location.host.includes("staging-iis")) ? 'https://federation-qa.corpnet4.com/as/introspect.oauth2' : '',
    tokenURL: "/getAuthToken.aspx",
    redirectURL: (window.location.host.includes("staging-iis")) ? 'https://optime-haleon-es.staging-iis.ch-internet.com/'  : 'https://optime.haleon.es/',
    //redirectURL: (window.location.host.includes("staging-iis")) ? 'https://optime-gskconsumer-gsk-es.staging-iis.ch-internet.com/'  : 'https://optime-gskconsumer.gsk.es/',
    //redirectURL: (window.location.host.includes("staging-iis")) ? 'https://optime-haleon-es.staging-iis.ch-internet.com/'  : 'https://optime-haleon-es.softlaunch-iis.ch-internet.com/',
    //clientId: (window.location.host.includes("staging-iis")) ? '4702bbd9214840198eae6c3ae5c45a68'  : '7a2ebf17e192498caf7bbe0f4dd92b96',
    //clientSecret: (window.location.host.includes("staging-iis")) ? '3SJYrT2cGj3sRQx4A2aRg48sees2OxKoW9iSBhU0ozWD9eq7WArmiLBjokHZAV8mWjcX' : 'zCy3trP4fSqtzGyZhCdQt1YSHTl0MK0kCrYz2f4IYDU8jAVfSAKMj35tFc58B9Zvr4kb',
    clientId: (window.location.host.includes("staging-iis")) ? '4702bbd9214840198eae6c3ae5c45a68'  : 'b3eb1b17e6404ec8afbdc5c9452768f0',
    clientSecret: (window.location.host.includes("staging-iis")) ? '3SJYrT2cGj3sRQx4A2aRg48sees2OxKoW9iSBhU0ozWD9eq7WArmiLBjokHZAV8mWjcX' : '1RHumxbLsBVTgO8Vo4LjCtUDywjIcO7UQ1czJlrkAaom4mD6DYYWzdPqbwha6msdZcIi',
    intermCode: '',
    accessToken: ''
  },
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
