<template>
  <ion-grid style="height: calc(100% - 65px);">
    <ion-row style="position: absolute; right: 85px; top: 25px;" justify-content-center align-items-center>
      <span style="font-size: 12px; text-align: right;">{{userName}}</span>
    </ion-row>
    <ion-row justify-content-center align-items-center class="ionRowCenter">
      <h1>{{this.$global.formatDate(diaryView.fecRegistro,null)}}</h1>
      <h1 v-if="diaryView.btnInActivo == 'false' && diaryView.btnOutActivo == 'false'">{{diaryView.textoJornadaDiaria}}</h1>
      <button
        type="button"
        v-if="diaryView.btnInActivo == 'true'"
        id="btnEntrad"
        class="btn-gsk1"
        style="background-color: #D0661C;"
        @click="clickEntrada"
      >ENTRADA</button>
      <button
        type="button"
        v-if="diaryView.btnOutActivo == 'true'"
        id="btnEntrad"
        class="btn-gsk1"
        style="background-color: #9CCC94; "
        @click="clickSalida"
      >SALIDA</button>
      <sliderOptions
        :arrOptions="diaryView.listadoInc"
        titlePopup="Seleccionar"
        titleBtn="AUSENCIAS"
        btnClass="btn-gsk2"
        btnStyle="background-color: #EBACC0"
        v-if="diaryView.btnInActivo == 'true'"
        @confirm="clickAusencias"
        class="theme-orange"
        type="range-today"
        input-style="display:none"
        input-id="inputFecAusencias"
      ></sliderOptions>
      <button
        type="button"
        id="btnEntrad"
        class="btn-gsk2"
        style="background-color: #F7E3C5; width: 50%;"
        v-if="diaryView.btnInActivo == 'true'"
        @click="abrirDatepicker('inputFecVacaciones')"
      >VACACIONES</button>
      <datetime
        input-id="inputFecVacaciones"
        format="yyyy-MM-dd"
        v-model="objFecVacaciones"
        class="theme-orange"
        type="range-today"
        input-class="hiddenInput"
        input-style="display:none"
        :phrases="{ok: 'Aceptar', cancel: 'Cancelar'}"
        @confirm="clickVacaciones"
      ></datetime>
    </ion-row>
  </ion-grid>
</template>

<script>
import { Datetime } from '@/components/vue-datetime'
import sliderOptions from "../vue-datetime/sliderOptions.vue";

export default {
  data: function() {
    return {
      fecVacaciones: "",
      userName: "",
      objFecVacaciones: {
        fecIni: "",
        fecFin: ""
      },
      config: new Object(),
      diaryView: {
        fecRegistro: "",
        numMaxSegs: "3",
        btnInActivo: "true",
        btnOutActivo: "false",
        textoJornadaDiaria: "",
        segundosTeoricos: 4000,
        //preguntaComida: true,
        segundosTeoricosComida: 4800,
        listadoInc: new Array()
      }
    };
  },
  methods: {
    secondsToHms: function(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        //var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos ") : "";
        //var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        return hDisplay + mDisplay; // + sDisplay; 
    },
    abrirDatepicker: function(id) {
      console.log("Dentro de abrirDataPicker Principal");
      window.$("#" + id).click();
    },
    clickVacaciones: function(){
      this.selectAus("9",this.objFecVacaciones.fecIni,this.objFecVacaciones.fecFin)
    },
    clickAusencias: function(objAus){
      console.log(objAus)
      this.selectAus(objAus.option.id,objAus.fecSel.fecIni,objAus.fecSel.fecFin)
    },
    selectAus: function(idAus,fIni,fFin) {
      var that = this;
      this.$store.dispatch('setIncidencia',{'tipo': "D",'idAus': idAus,'fIni': fIni,'fFin': fFin}).then(() => {
        that.$store.getters.alertIonic("OK", "", this.config.msg_confirmIncidencia);
        window.$.extend(this.diaryView, this.$store.getters.getDiaryView);
      }); 
    },
    clickEntrada: function() {
      var that = this;
      this.$store.dispatch('setEntrada',{'params':null,'dia':null}).then(() => {
        that.$store.getters.alertIonic("OK", "", this.config.msg_confirmFichajeEntrada);
        window.$.extend(this.diaryView, this.$store.getters.getDiaryView);
      });
    },
    clickSalida: async function() {    
      
      var auxData = this.diaryView;
      let dIni = Date.parse("2000-01-01T" + auxData.HorEntradaReal + ":00");
      let dFin = ""
      if((auxData.HorSalidaReal != null) && (auxData.HorSalidaReal != "")){
        dFin = Date.parse("2000-01-01T" + auxData.HorSalidaReal + ":00");
      }else{
        let todaynow = new Date();
        let hh = todaynow.getHours() < 10 ? "0"+todaynow.getHours() : todaynow.getHours()
        let mi = todaynow.getMinutes() < 10 ? "0"+todaynow.getMinutes() : todaynow.getMinutes()
        dFin = Date.parse("2000-01-01T" + hh + ":" + mi + ":00");
      }

      let segsTrabajados = (dFin - dIni) / 1000;
      /*let msgAlert  = "dIni : " + dIni + "\n";
          msgAlert += "dFin : " + dFin + "\n";
          msgAlert += "segsTrabajados : " + segsTrabajados + "\n";
      alert(msgAlert); */


      let chkExcesoHora = false;
      let paramURL = "";
      var d = new Date();
      var n = (d.getDay()) - 1; //0 tiene que se lunes, 1 martes....

      await this.$store.getters.preguntasSalida({indexDia: n,
                                            chkComida: auxData.preguntaComida, 
                                            segsJornada: auxData.segundosTeoricos,
                                            segsJornadaComida: auxData.segundosTeoricosComida,
                                            segsJornadaTrabajada: segsTrabajados }).then((res) => {
        chkExcesoHora = (res.respExcesoHoras) ? true : false;
        paramURL += (res.respExcesoHoras)  ? "&excesoTiempo=" + res.respExcesoHoras  : "&excesoTiempo="; 
        paramURL += (res.respExcesoComida) ? "&excesoComida=" + res.respExcesoComida : "&excesoComida=";
        paramURL += (res.respTeletrabajo) ? "&teletrabajo=" + res.respTeletrabajo : "&teletrabajo=";

      });

      var that = this;
      this.$store.dispatch('setSalida',{'params':paramURL,'dia':null}).then(() => {
        let msgAlert = this.config.msg_confirmFichajeSalida
          if(chkExcesoHora){
            var d = Number(segsTrabajados);
            var h = Math.floor(d / 3600);
            var m = Math.floor((d % 3600) / 60);
            //var s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos ") : "";
            var totDisplay = hDisplay + mDisplay; // + sDisplay;

            //msgAlert += " Presencia de " + totDisplay 
            msgAlert = this.config.msg_confirmFichajeSalidaHorario.replace("##TOTAL_HORAS##", totDisplay);
          }
          that.$store.getters.alertIonic("OK", "", msgAlert);
          window.$.extend(this.diaryView, this.$store.getters.getDiaryView);
        })
    }
  },
  created(){
    this.userName = this.$store.getters.getUserName;
  },
  mounted(){    
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.$global.ponerCarga();
    window.$.extend(this.diaryView, this.$store.getters.getDiaryView);
    this.$store.dispatch('wsDiaryView',{'fecdia':null,'act':true}).then(() => {
      window.$.extend(this.diaryView, this.$store.getters.getDiaryView);
      this.config = this.$store.getters.getJsonConfig;
      this.$global.quitarCarga();
    }).catch(function(err) {
      console.log(err);
      this.$global.quitarCarga();
    });
    
  },
  props: {
    segsTrabajados: {
      type: Number
    }
  },
  components: {
    datetime: Datetime,
    sliderOptions
  }
};

/*window.addEventListener("orientationchange", function() {
  console.log("the orientation of the device is now " + screen.orientation.angle);
});*/
</script>