import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/views/Login'
import Principal from '@/components/views/Principal'
import Semanal from '@/components/views/Semanal'
import Informe from '@/components/views/Informe'
import Contacta from '@/components/views/ContactaRRHH'
import Ajustes from '@/components/views/Ajustes'
import Cerrar from '@/components/views/CerrarSesion'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: "/",
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/principal',
      name: 'Principal',
      component: Principal
    },
    {
      path: '/semanal',
      name: 'Semanal',
      component: Semanal
    },
    {
      path: '/informe',
      name: 'Informe',
      component: Informe
    },
    {
      path: '/contacta',
      name: 'Contacta',
      component: Contacta
    },
    {
      path: '/ajustes',
      name: 'Ajustes',
      component: Ajustes
    },
    {
      path: '/cerrar',
      name: 'Cerrar',
      component: Cerrar
    }
  ]
})